import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";

function sendCounter() {
  console.log("hello log");
  axios
    .post(`${process.env.REACT_APP_URL_DEST}/counter`, {
      site: "porchcap",
      unique: true,
      path: "/",
    })
    .then((res) => {
      console.log(res);
      console.log(res.data);
    });
}

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://porchcapital.com/">
        Porch Capital
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const cards = [1, 2, 3, 4];

const theme = createTheme();
let didInit = false;

export default function Album() {
  useEffect(() => {
    if (!didInit) {
      didInit = true;
      sendCounter();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <CameraIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" noWrap>
            Porch Capital
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Porch Capital
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              We strive to give excellent service. Your best point of contact is
              the Resident Portal. You can manage everything you need there,
              including manage rent payments and enter maintenance requests.
            </Typography>
            <Stack sx={{ pt: 4 }} spacing={2} justifyContent="center">
              <Link
                href="https://rentconnect.managebuilding.com"
                variant="button"
              >
                Go to Tenant Portal
              </Link>
              <Typography>
                Alternatively, if you have a special case and need to talk to a
                property manager, you can give them a call.
              </Typography>
              <Typography>816-652-2004</Typography>
            </Stack>
          </Container>
        </Box>
      </main>
      {/* Footer */}
      <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Fin
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Thanks for visiting, come again.
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}
